import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import Image from 'gatsby-image'
import {navigate} from 'gatsby-link'
import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'

import BlockContent from '../components/portableText'

import styles from '../components/page.module.css'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isValidated: false, test: []}
  }

  handleChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query sanityPage {
            sanityContact {
              title
              _rawBody
              mainImage {
                asset {
                  fluid(maxWidth: 1600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              formSettings {
                # subject
                # to
                thankyou
              }
              seoSettings {
                title
                description
                openGraphImage {
                  asset {
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => (
          console.log({data}),
          <>
            <Layout>
              {data.sanityContact.seoSettings &&
                data.sanityContact.seoSettings.title &&
                data.sanityContact.seoSettings.description &&
                 (
                  <SEO
                    title={data.sanityContact.seoSettings.title}
                    description={data.sanityContact.seoSettings.description}
                    ogimage={data.sanityContact.seoSettings.openGraphImage && data.sanityContact.seoSettings.openGraphImage.asset ? data.sanityContact.seoSettings.openGraphImage.asset.url : null}
                  />
                )}

              <article className={styles.root}>
                {data.sanityContact.mainImage && data.sanityContact.mainImage.asset && (
                  <div className={styles.mainImage}>
                    <div className="grunge-edge first" />
                    <Image
                      fluid={data.sanityContact.mainImage.asset.fluid}
                      alt={data.sanityContact.title}
                    />
                    <div className="grunge-edge bottom" />
                  </div>
                )}
                <Container>
                  <section className="section">
                    <div className="container">
                      <div className="content">
                        {data.sanityContact._rawBody && (
                          <BlockContent blocks={data.sanityContact._rawBody} />
                        )}
                        <form
                          name="contact"
                          method="post"
                          action={data.sanityContact.formSettings.thankyou}
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                          onSubmit={this.handleSubmit}
                          css={{maxWidth: '480px'}}
                        >
                          <input type="hidden" name="form-name" value="contact" />
                          <div hidden>
                            <label>
                              Don’t fill this out:{' '}
                              <input name="bot-field" onChange={this.handleChange} />
                            </label>
                          </div>
                          <div className="field">
                            <label className="label" htmlFor={'name'}>
                              Your name
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type={'text'}
                                name={'name'}
                                onChange={this.handleChange}
                                id={'name'}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label className="label" htmlFor={'email'}>
                              Email
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type={'email'}
                                name={'email'}
                                onChange={this.handleChange}
                                id={'email'}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label className="label" htmlFor={'message'}>
                              Message
                            </label>
                            <div className="control">
                              <textarea
                                className="textarea"
                                name={'message'}
                                onChange={this.handleChange}
                                id={'message'}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <button className="button is-link" type="submit">
                              Send
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </Container>
              </article>
            </Layout>
          </>
        )}
      />
    )
  }
}
